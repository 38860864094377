// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.

.link_to_embed {
  font-size: 0.85rem;
  padding: 1rem 0 0 1rem;
}

.trix-content {
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  .embed {
    display: inline-block;
    line-height: 1;
    margin: 1em 0 !important;
    padding: 0 !important;
    width: 100%;
  }

  iframe, twitter-widget {
    display: inline-block !important;
  }
}

// Trix attachment formatting
.attachment--preview {
  margin: 0.6em 0;
  text-align: center;
  width: 100%;
}

// Tribute styles
.tribute-container {
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 0 4px rgba(0,0,0,0.1), 0 5px 20px rgba(0,0,0,0.05);

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    background: #fff;
    padding: 0.2em 1em;
    min-width: 15em;
    max-width: 100%;
  }

  .highlight {
    @apply bg-primary-500;
    color: #fff;

    span {
      font-weight: bold;
    }
  }
}

trix-toolbar .trix-button-row {
  flex-wrap: wrap !important;
}

// Tweet embeds
blockquote.twitter-tweet {
    display: inline-block;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);
    margin: 10px 5px;
    padding: 0 16px 16px;
    max-width: 468px
}

blockquote.twitter-tweet p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px
}

blockquote.twitter-tweet a {
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    outline: 0 none
}

blockquote.twitter-tweet a:hover,blockquote.twitter-tweet a:focus {
    text-decoration: underline
}

blockquote.twitter-tweet {
    position: relative;
    background: white;
    padding: 72px 20px 28px !important;
    box-shadow: none;
    border: 1px solid #e1e8ed;
    border-radius: 4px;
    margin: 0;
    font-style: normal;
    text-align: left;
    width: 500px;
    max-width: 100%
}

@media (max-width: 369px) {
    blockquote.twitter-tweet {
        padding:60px 17.5px 21.5px !important
    }
}

blockquote.twitter-tweet:before {
    content: 'Follow';
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5.5px 12px 6.5px 33px;
    background: url(icons/twitter.svg) 9px center no-repeat;
    background-size: 21px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #ccc;
    font-size: 14px
}

@media (max-width: 369px) {
    blockquote.twitter-tweet:before {
        display:none
    }
}

blockquote.twitter-tweet:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 36px;
    height: 36px;
    background: #eee url(icons/person.svg) center center no-repeat;
    border-radius: 4px
}

@media (max-width: 369px) {
    blockquote.twitter-tweet:after {
        top:17.5px;
        left: 17.5px
    }
}

blockquote.twitter-tweet p {
    white-space: pre-wrap;
    margin: 0 0 28px
}

@media (max-width: 369px) {
    blockquote.twitter-tweet p {
        font-size:14px;
        margin-bottom: 16px
    }
}

blockquote.twitter-tweet a {
    border: 0;
    box-shadow: none;
    color: #2b7bb9
}

blockquote.twitter-tweet>a {
    color: #888
}
