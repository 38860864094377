.alert {
  @apply border border-gray-500 bg-gray-100 text-gray-700 px-4 py-2 rounded font-sans;

  &.alert-info {
    @apply border-primary-500 bg-primary-100 text-primary-600;
  }

  &.alert-success {
    @apply border-secondary-600 bg-secondary-100 text-secondary-700;
  }

  &.alert-error {
    @apply border-danger-500 bg-danger-100 text-danger-600;
  }

  &.alert-warning {
    @apply border-yellow-500 bg-yellow-100 text-yellow-600;
  }

  a {
    @apply font-semibold;
  }
}

.notice {
  @apply flex items-center text-sm justify-center font-bold px-4 py-3;

  &.notice-info {
    @apply bg-blue-100 text-blue-600;
  }

  &.notice-success {
    @apply bg-green-100 text-green-600;
  }

  &.notice-error {
    @apply bg-red-100 text-red-600;
  }

  &.notice-warning {
    @apply bg-yellow-100 text-yellow-600;
  }
}
