.font-display {
  font-size: 4rem; // 64px

  @apply font-black leading-tight tracking-tight;
}


h1,
.h1 {
  font-size: 2.5rem; // 48px

  @apply font-black leading-tight tracking-tight;

}

h2,
.h2 {
  font-size: 2.25rem; // 36px

  @apply font-black leading-tight;
}

h3,
.h3 {
  font-size: 1.5rem; // 24px

  @apply font-black leading-normal;
}

h4,
.h4 {
  font-size: 1.125rem; // 18px

  @apply font-black leading-normal;
}

h5,
.h5 {
  @apply font-bold uppercase tracking-wide text-sm; // .875rem
}

.link {
  @apply text-primary-500;

  &:hover,
  &:focus {
    @apply text-primary-600;
  }
}
