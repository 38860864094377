body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.main {
  flex: 1;
}

html {
  font-size: 14px;
}

@media (min-width: 420px) {
  html {
    font-size:16px;
  }
}

a {
  @apply text-primary-500;

  &:hover,
  &:focus {
    @apply text-primary-600;

    transform: none;
  }
}

ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

.table {
  @apply w-full;
  th, td {
    @apply align-top;
  }

}

.table-responsive {
  @apply block w-full overflow-x-auto;
  -webkit-overflow-scrolling: touch;
}
